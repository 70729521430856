/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/buttons";
@import '~material-icons/iconfont/material-icons.css';

@import "assets/font/stylesheet.css";
@import "src/theme.scss";
@import '~igniteui-angular/lib/core/styles/themes/index';
// @include igx-core();
// @include igx-theme($default-palette, $legacy-support: true);
// :root {
//   @include css-vars-from-palette($default-palette);
// }


*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

*:focus {
  outline: none !important;
}

html {
  font-family: robotoregular;
  scroll-behavior: smooth;
}
@font-face {
  font-family : 'signature-1';
   src: url('assets/font/amandasignature.ttf');
}

@font-face {
font-family : 'signature-2';
src: url('assets/font/AnastasiaScriptPersonalUse.ttf');
}


@font-face {
font-family : 'signature-3';
src: url('assets/font/Battalion.ttf');
}

body {
  font-size: 12px;
  margin: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: robotoregular;
  width: 100%;
}

.scroll-10 {
  overflow-x: hidden;
  overflow-y: auto;
}


.scroll-10::-webkit-scrollbar {
  width: 10px;
}

.scroll-10::-webkit-scrollbar-track {
  background-color: $light;
  border-radius: 5px;
  box-shadow: inset 0 0 6px $light;
  -webkit-box-shadow: inset 0 0 6px $light;
}

.scroll-10::-webkit-scrollbar-thumb {
  background-color: $light4;
  border-radius: 5px;
  box-shadow: inset 0 0 6px $light4;
  -webkit-box-shadow: inset 0 0 6px $light4;
}


.scroll-8 {
  overflow-x: hidden;
  overflow-y: auto;
  // opacity: 0.9;
}


.scroll-8::-webkit-scrollbar {
  width: 10px;
}

.scroll-8::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 5px;
  box-shadow: inset 0 0 6px white;
  -webkit-box-shadow: inset 0 0 6px white;
}

.scroll-8::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 5px;
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent;
}

.scroll-5 {
  overflow-x: hidden;
  overflow-y: auto;
}

.scroll-5::-webkit-scrollbar {
  width: 5px;
}

.scroll-5::-webkit-scrollbar-track {
  background-color: $light;
  border-radius: 5px;
  box-shadow: inset 0 0 6px $light;
  -webkit-box-shadow: inset 0 0 6px $light;
}

.scroll-5::-webkit-scrollbar-thumb {
  background-color: $light4;
  border-radius: 5px;
  box-shadow: inset 0 0 6px $light4;
  -webkit-box-shadow: inset 0 0 6px $light4;
}

// .pointer {
//   cursor: pointer;
// }

// .tooltip {
//   background-color: white !important;
//   color: $info !important;
//   font-size: 11px !important;

//   -webkit-box-shadow: 0px 0px 5px 0px $light2;
//   -moz-box-shadow: 0px 0px 5px 0px $light2;
//   box-shadow: 0px 0px 5px 0px $light2;
// }

 
 

.popover[data-popper-placement^='top-start'] >.popover-arrow {
  bottom: -4px !important;
  left: 14px !important;
}

.popover[data-popper-placement^='top-end'] >.popover-arrow {
  bottom: -4px !important;
  left: -14px !important; 
 }
 .popover[data-popper-placement^='bottom-end'] >.popover-arrow {
  bottom: -4px !important;
  left: -14px !important; 
 }
.popover[data-popper-placement^='top'] >.popover-arrow {
  bottom: -4px !important;
 }

.popover[data-popper-placement^='bottom'] > .popover-arrow {
  top: -4px;
}

.popover[data-popper-placement^='left'] > .popover-arrow {
  right: -4px;
}

.popover[data-popper-placement^='right'] > .popover-arrow {
  left: -4px;
}

 .popover-arrow,
.popover-arrow::before {
  position: absolute !important;
  width: 8px !important;
  height: 8px !important;
  z-index: -1 !important;
  
}
.popover-arrow::before {
  content: '';
  transform: rotate(45deg) !important;
  background: #00aeef !important;
}
 
 .custom-popover{
  border: 1px solid lightgray !important;
  background-color: #fff !important;
  color: #6B6B6B !important;
  white-space: pre-line !important;
  font-family: robotoregular !important;
  font-size: 12px !important;
  width: 234px;
  height: 74px;
  border: none !important;
  display: block !important;
  border-radius: 10px;
  padding: 9px 12px 16px 12px;
  -webkit-box-shadow: 0px 0px 4px  #00000029 !important;
  -moz-box-shadow: 0px 0px 4px  #00000029 !important;
  z-index: 1000 !important;  
  box-shadow: 0px 0px 4px  #00000029 !important;
  opacity: 1 !important;
 }

 

 .custom-popover{
  .popover-arrow::before {
    content: '';
    transform: rotate(45deg) !important;
    background: #fff !important;
    -webkit-box-shadow:0.5px 0.5px 0px rgb(0 0 0 / 8%);
    -moz-box-shadow:0.5px 0.5px 0px rgb(0 0 0 / 8%);
    box-shadow: 0.5px 0.5px 0px rgb(0 0 0 / 8%);
   opacity: 1 !important;
  }
}
.interview-request-popover{
  .popover-arrow::before {
    content: '';
    transform: rotate(45deg) !important;
    background: #fff !important;
    -webkit-box-shadow:0.5px 0.5px 0px rgb(0 0 0 / 8%);
    -moz-box-shadow:0.5px 0.5px 0px rgb(0 0 0 / 8%);
    box-shadow: 0.5px 0.5px 0px rgb(0 0 0 / 8%);
   opacity: 1 !important;
  }
}
.offer-extended-popover{
  .popover-arrow::before {
    content: '';
    transform: rotate(45deg) !important;
    background: #fff !important;
    -webkit-box-shadow:0.5px 0.5px 0px rgb(0 0 0 / 8%);
    -moz-box-shadow:0.5px 0.5px 0px rgb(0 0 0 / 8%);
    box-shadow: 0.5px 0.5px 0px rgb(0 0 0 / 8%);
   opacity: 1 !important;
  }
}
 

// -------------------------START: Color Element---------------------------------------
.primary-element {
  background-color: $primary;
  color: white;
}

.info-element {
  background-color: $light4;
  color: $info;
}

.fg-info {
  color: $info;
}

.fg-primary {
  color: $primary;
}

.bg-primary {
  background-color: $primary;
}
.mat-dialog-container{
  border-radius: 15px !important;
}
// -------------------------END: Color Element---------------------------------------

// -------------------------START: Mat Menu------------------------------------------
.mat-menu-panel {
  box-shadow: 0px 0px 4px #00000029;

  img {
    height: 10px;
  }

  .mat-divider {
    margin: 0rem 1rem;
  }

  .mat-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: $info;
    font-size: 12px;
    height: 35px;
    line-height: 0;
  }
}
// -------------------------END: Mat Menu------------------------------------------

// ---------------------------START: Mat Tab-------------------------------------
.mat-tab-body-content {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.mat-tab-label {
  min-width: auto !important;
  padding: 0px 12px !important;
}

.mat-tab-label-content {
  font-size: 14px !important;
  font-family: robotomedium !important;
  color: $info;
}

.mat-tab-label-active {
  .mat-tab-label-content {
    color: $primary;
  }
}
.igx-calendar__header{
  display: none;
}
.igx-calendar__label{
  font-size: 12px !important;
  font-family: robotomedium !important;
  color: #6B6B6B;
}
.igx-calendar-picker{
  border-bottom: 1px solid #DADADA !important;
}
.igx-calendar-picker__date{
  color: #0F0F59;
  font-size: 14px;
  font-family: robotobold !important;
}
.igx-calendar-picker__date:hover{
  color: #0F0F59;
  font-size: 14px;
  font-family: robotobold !important;
}
.igx-calendar__date--current .igx-calendar__date-content{
  border: none !important;
  color: #6B6B6B !important;
  font-weight:1 !important;
  font-family: robotoregular !important;
  font-size: 12px !important;
  background: white !important;
}
.igx-calendar__date--selected .igx-calendar__date-content{
  border: 2px solid #00AEEF !important;
  color: #00AEEF !important;
  background: white !important;
}
.igx-calendar__date--selected .igx-calendar__date-content:focus{
 
  background: white !important;
}
.mat-tab-header {
  border-bottom: none !important;
  position: sticky !important;
  top: 0;
  background-color: #fff !important;
  z-index: 999 !important;
}

.mat-ink-bar {
  background-color: $primary !important;
}

.mat-tab-body {
  padding-top: 0.8rem;
}
// ---------------------------End: Mat Tab-------------------------------------

ngb-rating span.ng-star-inserted {
  color: $primary;
  font-size: 23px;
}

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

// .cdk-overlay-pane{

//   min-width: 23% !important;
//   pointer-events: auto !important;
//   font-size: 12px !important;
//   top: 35% !important;
//   left: 69%!important;
//   transform: translateX(-40px) translateY(-12px);

// }

.mt-2,
.my-2 {
  margin-top: 1.5rem !important;
}
// .mt-3, .my-3{
//   margin-top: 3.5rem !important;
// }

.ml-2 {
  margin-left: 2.5rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-5 {
  margin-left: 4.8rem !important;
}
.panel-width {
  min-width: 100% !important;
}

.response-error {
  color: red;
}
.mat-select-panel-wrap {
  // margin-left: 6rem !important;
}
.advanced {
  margin-left: 9rem !important;
}

.mat-tab-label {
  opacity: 4.6 !important;
}
.consultant_info_tabs {
  padding-top: 0px !important;
}
.selected-radio {
  width: 15px;
  padding-bottom: 0.3rem;
}
.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: lightgray !important;
}
.mat-standard-chip {
  min-height: 28px !important;
}
@import '@angular/material/theming';

mat-chip {
    @include mat.elevation(0);
}

.mat-pseudo-checkbox {
  border-radius: 10px !important;
}
.mat-select-panel {
  border-radius: 9px !important;
  // overflow: hidden !important;
}
.cdk-overlay-container {
  // position: absolute !important;
}
.mat-menu-panel {
  min-width: 60px !important;
  min-height: 26px !important;
  text-align: center !important;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: none !important;
}

//Rich Angular editor

.angular-editor-button {
  // background-color: #fff !important;
  vertical-align: middle !important; 
  border: 1px solid #ddd !important;
  padding: 0.1rem !important;
  min-width: 1.5rem !important;
  margin-right: 5px !important;
  border-radius: 6px !important;
}
button {
  outline: none;
}
  .mat-button-focus-overlay {
  background-color: transparent!important;
  display: none;
}


.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button.active{
  background-color: #707070 !important;
  color: white !important;
}
.angular-editor-toolbar  .angular-editor-toolbar-set .angular-editor-button{
  color: #6B6B6B;
}
.angular-editor-toolbar {
  background-color: #dbdbdb !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
}
#insertUnorderedList- {
  margin-left: -14px !important;
}

.angular-editor-textare {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.angular-editor-textarea {
  margin-top: 0px !important;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: lightgray !important;
}
.mat-form-field-label {
  color: #9D9D9D;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: black !important;
}
.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  background: lightgray;
}
.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  color: red !important;
}

.mat-select-arrow {
  color: lightgrey;
}

.mat-select-placeholder {
  color: #9D9D9D !important;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: none !important;
  padding: 0 !important;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: white !important;
}
.mat-form-field-appearance-fill .mat-form-field-label-wrapper {
  top: 1 !important;
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.5em 0 0em 0;
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  font-size: 10px;
}
.mat-datepicker-toggle {
  // background: url("assets/icons/close.svg") no-repeat !important;
}
.mat-select-panel {
  // max-height: 155px !im portant;
}
.panel-style {
  margin-left: -6rem !important;
}

password-visibility-control {
  position: absolute;
  bottom: -10px;
  right: 0;
}

.pie-label {
  font-size: 12px !important;
  font-family: robotobold;
  color: #6b6b6b !important;
}
.swiper-pagination-bullets {
  margin-left: -40% !important;
}
.swiper-pagination-bullet {
  background: transparent !important;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  background-color: white !important;
}
.toast-error {
  background-color: #ffffff !important;
  // color: #6b6b6b !important;
  text-align: left !important;
  font-family: robotobold !important;
  letter-spacing: 0px !important;
  color: #6b6b6b !important;
  opacity: 1 !important;
}
.toast-container .ngx-toastr {
  padding: 21px 15px 10px 10px !important;
  box-shadow: 0px 6px 20px #00000026 !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  width: 370px !important;
  height: 60px !important;
}
.toast-container { 
  img{
    padding-bottom: 3px;
  }
}
.toast-close-button{
  background: url("assets/icons/crossIconbutton.svg") no-repeat !important;
  position: relative;
  right: 0.5em;
  top: 0em !important;
  height: 29px;
  width: 28px;
  span{
   display: none !important;
  }
  
}
.cropper {
  outline: var(--cropper-outline-color, white) solid 100vw !important;
}
.rounded {
  border-radius: 50% !important;
  border: 2px solid white !important;
}

.cropper.rounded {
  outline-color: white !important;
}
.cropper.rounded:after {
  border: 2px solid white !important;
  box-shadow: 0 0 0 0 white !important;
  opacity: 1 !important;
}
image-cropper span.rezie.top-left span.square {
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
}
img.source-image {
  border-radius: 50% !important;
}
.igx-calendar-picker{
  height: 5em !important;
}
.igx-calendar-picker__prev{
  .igx-icon{
    width: 30px !important;
    height: 30px !important;
    color: white !important;
    // border-radius: 50% !important;
    // background: #red !important;
    background: url("assets/icons/chevron_circle_left_solid.svg") no-repeat !important;
    font-size: 00px !important;
  }
}
.igx-calendar-picker__next {
  .igx-icon{
    width: 30px !important;
    height: 30px !important;
    color: white !important;
    // border-radius: 50% !important;
    // background: #red !important;
    background: url("assets/icons/chevron_circle_right_solid.svg") no-repeat !important;
    font-size: 00px !important;
  }
}
// .igx-icon{
//   width: 30px !important;
//   height: 30px !important;
//   color: white !important;
//   border-radius: 50% !important;
//   // background: #red !important;
//   background: url("assets/icons/chevron_circle_right_solid.svg") no-repeat !important;
//   font-size: 00px !important;
// }
.igx-calendar-picker div{
  margin-left: 20px;
  margin-right: 20px;
}

@-webkit-keyframes color-change-2x {
  0% {
      background: #fdf8f0;
  }
  100% {
      background: #f6e7ec;
  }
}

@keyframes color-change-2x {
  0% {
      background: #fdf8f0;
  }
  100% {
      background: #f6e7ec;
  }
}

@-webkit-keyframes fade-in {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes fade-in {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.headerAlignSyle {
  text-align: right !important;
}

// $green-palette: igx-palette($primary: #09f,$secondary: #72da67, $surface: #333);

.fin-dark-theme {
  // @include igx-dark-theme($green-palette);
   background: #333;

   ::-moz-placeholder {
       opacity: 1;
   }
}

.dark-theme {
  background: #333;
  color: #fff;
  // @include igx-dark-theme($green-palette, $legacy-support: true);

  .grid-chart-contextmenu-wrapper{
      // @include igx-fluent-dark-theme($fluent-excel-palette);
  }
}


.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button{
  width: 1.3em !important;
  // background: white !important;
}


.toast-container .ngx-toastr{
  margin: 0 50px 52px 0px !important;
  padding: 20px !important;
}

.fa + .fa{
  background-image: url('https://www.w3schools.com/images/tshirt.jpg');
}

.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent; /* Left side of the triangle */
  border-right: 10px solid transparent; /* Right side of the triangle */
  border-bottom: 10px solid #fff; /* Bottom of the triangle */
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}