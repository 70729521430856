$primary: #00aeef;
$secondary: #0f0f59;
$success: #39b54a;
$info: #6b6b6b;
$danger: #ed1c24;

// light colors
$light: #efefef;
$light2: #afd1de;
$light3: #f7f7f7;
$light4: #dbdbdb;
$light6: #e9e9e9;
$light7: #def4ff;
$light8: #a3cfe0;

// dark colors
$dark: #0000004d;
$dark1: #707070;
