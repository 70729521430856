/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 1, 2019 */

@font-face {
    font-family: 'robotobold';
    src: url('roboto-bold-webfont.woff2') format('woff2'),
         url('roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotomedium';
    src: url('roboto-medium-webfont.woff2') format('woff2'),
         url('roboto-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoregular';
    src: url('roboto-regular-webfont.woff2') format('woff2'),
         url('roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'robotomediumitalic';
    src: url('Roboto-MediumItalic.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'robotoitalic';
    src: url('Roboto-Italic.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;

}
/* @font-face {
    font-family: 'ralewaymedium';
    src: url('Raleway-Medium.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;

} */
/* @font-face {
    font-family: 'ralewaysemiBold';
    src: url('Raleway-SemiBold.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
} */
/* @font-face {
    font-family: 'ralewayregular';
    src: url('Raleway-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;

} */
/* @font-face {
    font-family: 'ralewaybold';
    src: url('Raleway-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;

} */









@font-face {
    font-family: 'ralewayregular';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway Regular'), url('Raleway-Regular.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway'), url('Raleway-Regular.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway Italic'), url('Raleway-Italic.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway Thin'), url('Raleway-Thin.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway Thin Italic'), url('Raleway-ThinItalic.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway ExtraLight'), url('Raleway-ExtraLight.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway ExtraLight Italic'), url('Raleway-ExtraLightItalic.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway Light';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway Light'), url('Raleway-Light.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway Light Italic'), url('Raleway-LightItalic.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'ralewaymedium';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway Medium'), url('Raleway-Medium.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway Medium Italic'), url('Raleway-MediumItalic.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'ralewaysemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway SemiBold'), url('Raleway-SemiBold.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway SemiBold Italic'), url('Raleway-SemiBoldItalic.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'ralewaybold';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway Bold'), url('Raleway-Bold.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway Bold Italic'), url('Raleway-BoldItalic.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway ExtraBold'), url('Raleway-ExtraBold.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway ExtraBold Italic'), url('Raleway-ExtraBoldItalic.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway Black';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway Black'), url('Raleway-Black.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Raleway Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Raleway Black Italic'), url('Raleway-BlackItalic.woff') format('woff');
    }